.guest-selfie {
    width: 300px;
    height: 300px;
}

@media screen and (max-width: 768px) {
	.guest-selfie {
        width: 250px;
        height: 250px;
    }

    #guest-identification, #guest-details{
        align-items: center !important;
        padding-top: 3em !important;
        width: 100% !important;
    }
}

.px-30 {
    padding: 0 30px !important;
}