.swal2-popup {
	background-color: #1d1f27 !important;
}
#swal2-title {
	color: #fff !important;
}
.swal2-html-container {
	color: #fff !important;
}
.swal2-html-container ul {
	color: #fff !important;
}
/* .swal2-confirm{
    background-color: ;
} */

.activate-view {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-column-gap: 16px;
	grid-row-gap: 16px;

	/* @media (min-width: 768px) {
		grid-template-columns: repeat(2, 1fr);
		grid-column-gap: 16px;
	}

	@media (min-width: 1024px) {
		grid-template-columns: repeat(3, 1fr);
		grid-column-gap: 16px;
	}

	@media (min-width: 1280px) {
		grid-template-columns: repeat(4, 1fr);
		grid-column-gap: 16px;
	} */
}

@media (max-width: 1024px) {
	.activate-view {
		grid-template-columns: repeat(1, 1fr);
	}
}

.activate-view__empty-tokens-list {
	font-weight: bold;
}
