.borderQualify {
    border-top: 3px solid #3498db !important;
    color: #3498DB !important;
}

.borderAnalyze {
    border-top: 3px solid #f39c12 !important;
    color: #f39c12 !important;
}

.borderReady {
    border-top: 3px solid #27ae60 !important;
    color: #27ae60 !important;
}

@media only screen and (max-width: 768px) {
    .cardsContainer {
        flex-direction: column !important;
        gap: 1.5rem !important;
    }
}