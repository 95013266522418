.borderActive {
    border-color: #3498DB !important;
    color: #3498DB !important;
}

.borderStarted {
    border-color: #27AE60 !important;
    color: #27AE60 !important;
}

.borderConcluded {
    border-color: #E74C3C !important;
    color: #E74C3C !important;
}