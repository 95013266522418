@media (max-width: 1024px) {

	.user-spots-list__card {
		max-width: 300px;
		min-width: 300px;
	}
}

@media (max-width: 768px) {
	.user-spots-list {
		flex-direction: column !important;
		align-items: center !important;
	}
}

.user-spots-list__card {
	max-width: 325px;
	min-width: 325px;
}

.user-spots-list__empty-spots-list {
	display: flex;
	font-weight: bold;
}