//
//  Page Wrapper
//
.page-wrapper {
	display: flex;
	flex: 1 0 auto;
	flex-direction: column;
  	margin-top: 0;
  	min-height: 300px;
}
