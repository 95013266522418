.user-register-list {
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	grid-column-gap: 16px;
	grid-row-gap: 16px;
}

@media (min-width: 768px) {
	.search-input {
		height: 44px;
	}

	.btn-cadastro {
		height: 44px;
		width: 280px;
		margin-left: 5%;
	}

	.header-sort {
		margin-right: 13%;
	}
}
@media (max-width: 1400px) {
	.user-register-list {
		grid-template-columns: repeat(4, 1fr);
	}
}

@media (max-width: 1024px) {
	.user-register-list {
		grid-template-columns: repeat(3, 1fr);
	}

	.search-input {
		height: 44px;
		width: 100%;
		margin-top: 20px;
	}

	.btn-cadastro {
		width: 100%;
		height: 44px;
	}

	.header-sort {
		margin-top: 20px;
	}
}

@media (max-width: 768px) {
	.user-register-list {
		grid-template-columns: repeat(1, 1fr);
	}

	.search-input {
		height: 44px;
		width: 100%;
		margin-top: 20px;
	}

	.btn-cadastro {
		width: 100%;
		height: 44px;
	}

	.header-sort {
		margin-top: 20px;
	}

	.header-cadastro {
		display: flex;
		flex-direction: column-reverse;
		justify-content: center;
		align-items: center;
	}
}

.user-register-list__card {
	max-width: 250px;
}

.user-register-list__empty-spots-list {
	display: flex;
	font-weight: bold;
}

.vl {
	border-left: 1px solid black;
	margin: 0px 10px 0px 10px;
}