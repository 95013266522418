//
// Custom
//

@use 'sass:map';

.scroll-margin {
	scroll-margin-top: map.get($header, height) + map.get($subheader, height) +
		map.get($page, padding-y);
}

.sticky-top-size {
	top: map.get($header, height) + map.get($subheader, height) + map.get($page, padding-y);
}

.border-transparent {
	--#{$prefix}border-opacity: 0;
}

.fit-image{
	object-fit: cover !important;
}

#app-menu {
	display: flex !important;
	justify-content: center !important;

	li {
		a span span {
			text-transform: uppercase !important;
		}
	}

	@media only screen and (max-width: 768px) {
		div {
			padding: 1.2rem !important;
		}
	}
}

.darkModeColor {
	li {
		a:not(.active) span span {
			color: #ffffff !important;
		}
	}
}

#userMenu {
	color: #b6b6b6 !important;
}