.cardRoot {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;

    transition: filter 0.2s;

    &:hover {
        filter: brightness(0.8);
    }
}

.cardBody {
    width: 280px;
    height: 198.8px;
    border-radius: 30px;
    box-shadow: 7px 6px 4px rgba(0, 0, 0, 0.25);
}

.cardActiveNew {
    background-color: #3498DB;
}

.cardOnboard {
    background-color: #27AE60;
}

.cardCheckOut {
    background-color: #E74C3C;
}

.cardTitle {
    font-size: 16px;
    font-weight: 700;
}

.cardQuantity {
    font-size: 56px;
    font-weight: 700;
}

.cardIcon svg {
    width: 45px;
    height: 45px;
}

.toolTipAutorizados {
    color: #f1c40f;
    margin: 0 0 0 5px !important;
}